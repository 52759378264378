<template>
 <div id="plan1" class="plan" v-title :data-title="title">
   <page-template :carousel-list="carouselList"
                  :plan1-active="plan1Active"
                  :plan1-details="plan1Details"
                  :plan2-list="plan2List"
                  :plan2-title="plan2Title"
                  :plan3-list="plan3List"
                  :plan4Show="true"/>
 </div>
</template>

<script>
import PageTemplate from '../components/template.vue'
import Public from "@/assets/js/public";
export default {
  name: 'Plan3',
  components: {
   PageTemplate
  },
  mixins: [],
  data () {
    return {
      title: this.$route.query.title || '解决方案',
      // 轮播列表
      carouselList: [
        { img: require('@/assets/images/10.jpg'), txt: '数字校园', txt2: '', txt3: '高校身边的数字化转型专家', jumpUrl: 'btn'},
      ],
      plan1Active: Public.plan3().routerName,
      // 当前方案概述详情
      plan1Details: Public.plan3(),
      plan2Title: {
        title: '建设目标', txt: 'Goal Of Building'
      },
      // 我们的经验
      plan2List: [
        {img: require('@/assets/images/28.fw.png'), title: '校园综合态势感知', txt: '实现智慧校园整体态势感知服务，提供整体运营、教学、科研、创新创业等成果的统一呈现。'},
        {img: require('@/assets/images/27.fw.png'), title: '决策分析', txt: '通过各类数据全融合，全面数据分析及全景图表应用，实现管理决策有依据。'},
        {img: require('@/assets/images/26.fw.png'), title: '监测预警', txt: '通过与各个管理子系统全连接，数据全融合，最终形成智慧校园管理的监控、预警、分析的统一运营中心。'},
        {img: require('@/assets/images/25.fw.png'), title: '智慧应用', txt: '通过三维可视化校园叠加后勤、教务、选课、OA办公等系统'},
      ],
      // 服务案例
      plan3List: [
        {img: require('@/assets/images/31.fw.png'), title: '河南大学', txt: '国家“双一流”建设高校，入选国家“111计划”、中西部高校基础能力建设工程'},
        {img: require('@/assets/images/30.fw.png'), title: '河南师范大学', txt: '是国家中西部高等教育振兴计划支持高校、国家“111计划”实施高校'},
        {img: require('@/assets/images/32.fw.png'), title: '新乡学院', txt: '是一所经教育部批准建立的公办全日制普通本科院校。学校始建于1949年'},
        {img: require('@/assets/images/school/kf.jpg'), title: '河南开放大学', txt: '前身是创办于1979年的河南广播电视大学，2020年11月正式更名为河南开放大学'},
        {img: require('@/assets/images/school/kjxy.jpg'), title: '河南科技学院', txt: '河南科技学院是一所省属普通本科院校。学校始建于1939年'},
        {img: require('@/assets/images/school/xg.jpg'), title: '新乡工程学院', txt: '是一所全日制民办普通本科高校。前身是创建于2003年的河南职业技术师范学院新科学院'},
        {img: require('@/assets/images/school/zxk.jpg'), title: '郑州信息科技职业学院', txt: '2002年由河南省政府批准成立的一所专科层次的全日制普通高职院校'},
        {img: require('@/assets/images/school/zs.jpg'), title: '周口师范学院', txt: '学校始建于1973年，2002年经教育部批准升格为本科院校。学校现占地1600余亩'},
        {img: require('@/assets/images/school/zw.jpg'), title: '周口文理职业学院', txt: '是2021年经河南省政府批准成立，教育部备案的公办专科层次全日制普通高校'},
        {img: require('@/assets/images/school/ch.jpg'), title: '河南测绘职业学院', txt: '是教育部备案的全国唯一一所测绘类公办专科层次的全日制普通高等职业院校'},
      ]
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
